import "./Home.css";
import React from "react"
import { AndroidFilled, AppleFilled } from "@ant-design/icons";
import Scan from "../../images/scan.png";
import AppIntroduce from "../../images/app-introduce.png"
import Introduce1 from "../../images/introduces/introduce-1.png";
import Introduce2 from "../../images/introduces/introduce-2.png";
import Introduce3 from "../../images/introduces/introduce-3.png";
import Fun1 from "../../images/funs/fun-1.png";
import Fun2 from "../../images/funs/fun-2.png";
import Fun3 from "../../images/funs/fun-3.png";
import Fun4 from "../../images/funs/fun-4.png";
import Fun5 from "../../images/funs/fun-5.png";
import Fun6 from "../../images/funs/fun-6.png";
import IntroduceTitle from "../../components/introduce_title/IntroduceTitle";
import ChineseMedicineList from "../../components/chinese_medicine_list/ChineseMedicineList";
import SimpleVideoPlayer from "../../components/simple_video_player";
import BookList from "../../components/book/BookList";

export default () => {
    const iosUrl = 'https://apps.apple.com/cn/app/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D/id1554030035?platform=iphone'
    const androidUrl = "https://res-oss.lighttcm.com/apk/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D%20latest.apk";

    const downloadButton = (icon, text, href) =>
    (
        <a className="download-button" href={href} target="_blank">
            {icon}
            <span style={{ width: '10px' }} />
            <span className="button-text">{text}</span>
        </a>
    )

    const moreFunItem = (src, text) =>
    (
        <div className="more-fun-item">
            <img src={src} draggable={false} />
            <p>{text}</p>
        </div>
    )

    return (
        <div className="page-home">
            <div className="content">
                <div className="app">
                    <div className="app-info">
                        <img className="app-introduce" src={AppIntroduce} draggable={false} />
                        <div className="scan">
                            <div className="qrcode" />
                            <div className="scan-tip">
                                <img src={Scan} draggable={false} />
                                <h3>手机扫描二维码</h3>
                                <span>下载【中济经络穴位】</span>
                            </div>
                        </div>
                        <div className="downloads">
                            {downloadButton(<AppleFilled />, "苹果下载", iosUrl)}
                            {downloadButton(<AndroidFilled />, "安卓下载", androidUrl)}
                        </div>
                    </div>
                    <div className="app-video">
                        <SimpleVideoPlayer width={300} height={670} src="https://res-oss.lighttcm.com/video/portal/advertisement.mp4" poster={`/images/advertisement.jpg`} />
                    </div>
                </div>
            </div>
            <div className="introduce introduce-one sub-content">
                <div className="introduce-text">
                    <div className="introduce-no no1" />
                    <h1>多样人体模型</h1>
                    <p>成人男性、成人女性、小儿、手、足、耳、面部</p>
                </div>
                <img className="introduce-img" src={Introduce1} draggable="false" />
            </div>
            <div className="introduce introduce-two sub-content">
                <div className="introduce-text">
                    <div className="introduce-no no2" />
                    <h1>经络俞穴全覆盖</h1>
                    <p>十二正经、奇经八脉、经外奇穴、董氏奇穴应有尽有</p>
                    <p>362个正穴 102个经外奇穴 208个董氏奇穴</p>
                </div>
                <img className="introduce-img" src={Introduce2} draggable="false" />
            </div>
            <div className="introduce introduce-three sub-content">
                <div className="introduce-text">
                    <div className="introduce-no no3" />
                    <h1>超详细内容解读</h1>
                    <p>穴位包含定位、解剖、主治、操作、配伍、针刺</p>
                    <p>艾灸、按摩、推拿、拔罐等内容</p>
                </div>
                <img className="introduce-img" src={Introduce3} draggable="false" />
            </div>
            <div className="introduce introduce-four sub-content">
                <div className="introduce-text">
                    <div className="introduce-no no4" />
                    <h1>672个+真人取穴示范教程</h1>
                    <p>中医行业数量最多分类最全的真人取穴视频</p>
                    <p>行业首个董氏奇穴取穴示范教程</p>
                    <p>各类外治手法标准教学，针灸、艾灸、推拿、按摩等通用手法演示</p>
                </div>
                <div className="quxue-video">
                    <img src="/images/四白.jpg" draggable="false" />
                    <SimpleVideoPlayer width={420} height={270} src="https://res-oss.lighttcm.com/video/portal/%E6%89%B6%E7%AA%81.mp4" poster={`/images/扶突.jpg`} />
                </div>
            </div>
            <div className="sub-content more-fun">
                <IntroduceTitle title="更多功能" />
                <div className="more-fun-list">
                    {moreFunItem(Fun1, "AI舌诊")}
                    {moreFunItem(Fun2, "视频课程")}
                    {moreFunItem(Fun3, "中医典籍")}
                </div>
                <div className="more-fun-list">
                    {moreFunItem(Fun4, "养生药膳")}
                    {moreFunItem(Fun5, "音频课程")}
                    {moreFunItem(Fun6, "子午流注时钟")}
                </div>
            </div>
            <div className="sub-content medicines">
                <IntroduceTitle title="药品中心" />
                <ChineseMedicineList />
                <a href="/medicines" className="to-medicines">查看更多</a>
            </div>
            <div className="sub-content more-fun">
                <IntroduceTitle title="中医典籍" />
                <BookList />
            </div>
        </div>
    )
}